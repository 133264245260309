@charset "utf-8";

$navbar-breakpoint: 0px;

@import '../node_modules/bulma/sass/utilities/mixins.sass';

$modal-background-background-color: bulmaRgba($scheme-invert, 0.4);

@import '~bulma/bulma';
@import '~bulma-switch';

body {
    max-width: fit-content;

    margin: auto;
}
